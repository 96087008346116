import React from 'react'
import styled from 'styled-components'
import ButtonShell from './ButtonShell'
import { motion } from 'framer-motion'

const StyledButton = styled.div`
  background-color: transparent;
  border: 1px solid ${(props) => props.theme.color.face.black};
  display: inline-block;
  min-width: 260px;
  text-align: center;
  text-transform: uppercase;

  & > a,
  & > button {
    font-family: ${(props) => props.theme.font.family.secondaryLight};
    font-size: ${(props) => props.theme.font.size.xm};
    color: ${(props) => props.theme.color.text.main};
    padding: 10px 15px;
    width: 100%;
    height: 100%;
    display: block;
  }
`

const ButtonDefault = ({ isAnchor, isCustom, to, children, className }) => (
  <motion.div
    whileTap={{ scale: 0.9 }}
    whileHover={{ scale: 1.1 }}
    className="d-inline-block"
  >
    <StyledButton className={className}>
      {isCustom ? (
        children
      ) : (
        <ButtonShell to={to} isAnchor={isAnchor}>
          {children}
        </ButtonShell>
      )}
    </StyledButton>
  </motion.div>
)

export default ButtonDefault
