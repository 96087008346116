import React, { Fragment, useState } from 'react'
import styled, { css } from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { motion } from 'framer-motion'

// Images
import Logo from 'img/logo.inline.svg'

// Components
import CustomLink from 'components/shared/CustomLink'

const StyledHeader = styled(motion.nav)`
  background-color: ${(props) => props.theme.color.face.light};
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  min-height: 100px;
  z-index: 9;
  transition: 500ms;
  padding-top: 40px;

  .sub-menu {
    display: none;
  }

  &:hover {
    height: auto;

    .sub-menu {
      display: block;
    }
  }
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
`

const Brand = styled(CustomLink)`
  margin-top: -31px;

  & > svg {
    height: 80px;
    width: 90px;
  }
`

const NavButton = styled.button`
  width: 80px;
  height: 50px;
  padding: 0 10px;
  text-align: center;
  z-index: 10;
`

const NavButtonTitle = styled.div`
  color: ${(props) => props.theme.color.text.light};
  text-transform: uppercase;
`

const NavButtonStripe = styled.div`
  background-color: ${(props) => props.theme.color.text.main};
  height: 2px;
  width: 70%;
  margin: 5px auto;
`

const Header = ({ setIsScrollable, isSticky }) => {
  const { fields } = useStaticQuery(graphql`
    {
      fields: wordpressWpComponenten(wordpress_id: { eq: 97 }) {
        acf {
          menuLeft: menu_left {
            link {
              title
              url
            }
            submenuLeft: menu_menu_left {
              link {
                title
                url
              }
            }
          }
          menuRight: menu_right {
            link {
              title
              url
            }
            submenuRight: menu_menu_right {
              link {
                title
                url
              }
            }
          }
        }
      }
    }
  `)

  return (
    <StyledHeader
      initial={{ boxShadow: 'none' }}
      animate={
        isSticky
          ? {
              boxShadow: '0px 5px 9px 0px rgba(0, 0, 0, 0.1)',
            }
          : { boxShadow: 'none' }
      }
    >
      <Container className="container">
        <MenuDesktop fields={fields} />

        <Brand to="/">
          <Logo />
        </Brand>
        <MenuMobile fields={fields} setIsScrollable={setIsScrollable} />

        <MenuDesktopSecond fields={fields} />
      </Container>
    </StyledHeader>
  )
}

const MenuWrapper = styled.div`
  background-color: ${(props) => props.theme.color.face.main};
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  z-index: 9999;
  padding: 30px 0 0;
  -webkit-transform: translate3d(0, 0, 1px);
  transform: translate3d(0, 0, 1px);

  & > ul {
    padding: 50px 0 0;
    list-style-type: none;
    text-align: center;
    width: 60%;
    margin: 0 auto;

    @media (max-width: 991px) {
      width: 100%;
      height: 100% !important;
      overflow-y: scroll;
    }

    & li {
      padding: 0;

      & a {
        font-family: ${(props) => props.theme.font.family.secondaryBold};
        font-size: ${(props) => props.theme.font.size.l};
        border-bottom: 1px solid #f1f1f1;
        display: block;
        padding: 10px 0;
      }

      &:last-child a {
        border-bottom: none;
      }
    }
  }
`

const NavButtonClose = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 999;
  text-align: center;
`

const NavButtonCloseCross = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  margin-left: 16px;

  &:before,
  &:after {
    background-color: ${(props) => props.theme.color.text.light};
    position: absolute;
    content: ' ';
    height: 33px;
    width: 2px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`

const NavButtonCloseTitle = styled.div`
  color: ${(props) => props.theme.color.text.light};
  text-transform: uppercase;
  margin-top: 3px;
`

const MenuMobile = ({ fields, setIsScrollable }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="d-block d-lg-none">
      <NavButton
        role="button"
        onClick={() => {
          setIsOpen(!isOpen)
          setIsScrollable(false)
        }}
      >
        <NavButtonStripe />
        <NavButtonStripe />
        <NavButtonStripe />
        <NavButtonTitle>Menu</NavButtonTitle>
      </NavButton>
      {isOpen && (
        <MenuWrapper>
          <NavButtonClose
            role="button"
            onClick={() => {
              setIsOpen(!isOpen)
              setIsScrollable(true)
            }}
          >
            <NavButtonCloseCross />
            <NavButtonCloseTitle>Sluiten</NavButtonCloseTitle>
          </NavButtonClose>
          <ul className="h-100">
            <Menu fields={fields} />
            <MenuSecond fields={fields} />
          </ul>
        </MenuWrapper>
      )}
    </div>
  )
}

const StyledMenuDesktop = styled.ul`
  flex: 1;
  /* align-items: center; */
  justify-content: space-around;
  list-style: none;
  margin-bottom: 0;
`

const MenuDesktop = ({ fields }) => (
  <StyledMenuDesktop className="d-none d-lg-flex">
    <Menu fields={fields} />
  </StyledMenuDesktop>
)

const MenuDesktopSecond = ({ fields }) => (
  <StyledMenuDesktop className="d-none d-lg-flex">
    <MenuSecond fields={fields} />
  </StyledMenuDesktop>
)

const Item = styled.span`
  display: block;

  & > a {
    font-family: ${(props) => props.theme.font.family.secondary};
    font-size: ${(props) => props.theme.font.size.sm};
    color: ${(props) => props.theme.color.text.main};
    text-transform: uppercase;
    min-width: 100px;
    display: block;
    letter-spacing: 1px;

    @media (max-width: 991px) {
      font-size: ${(props) => props.theme.font.size.l};
    }

    &[aria-current] {
    }

    &:hover {
    }
  }

  .sub-menu {
    /* background: #000; */
    /* padding: 1rem; */

    & a {
      @media (max-width: 991px) {
        color: white;

        font-size: ${(props) => props.theme.font.size.xm};
      }
    }
  }
`

const LinkCSS = css`
  font-family: ${(props) => props.theme.font.family.secondary};
  font-size: ${(props) => props.theme.font.size.sm};
  color: ${(props) => props.theme.color.text.main};
  text-transform: uppercase;
  letter-spacing: 1px;
  @media (max-width: 991px) {
    font-size: 40px;
  }
`

const Link = styled(CustomLink)`
  ${LinkCSS};
`

const BlancoLink = styled.span`
  ${LinkCSS};
  cursor: pointer;
  &:hover {
    overflow: hidden !important;
  }
`

const Menu = ({ fields }) => {
  return (
    <>
      {fields.acf.menuLeft.map(
        ({ link: { url, title }, submenuLeft }, index) => {
          const isLink = url !== '#'

          return (
            // eslint-disable-next-line react/no-array-index-key
            <Item key={index} className="position-relative text-center ">
              {isLink && <Link to={url}>{title}</Link>}
              {!isLink && <BlancoLink>{title}</BlancoLink>}
              <div className="sub-menu mt-lg-5 mt-4 mb-4">
                {submenuLeft.map((b, key) => {
                  if (b.link.title === '#')
                    return <Fragment key={key}></Fragment>

                  return (
                    <Item className="mb-2 py-1" key={key}>
                      <CustomLink to={b.link.url}>{b.link.title}</CustomLink>
                    </Item>
                  )
                })}
              </div>
            </Item>
          )
        }
      )}
    </>
  )
}

const MenuSecond = ({ fields }) => {
  return (
    <>
      {fields.acf.menuRight.map(
        ({ link: { url, title }, submenuRight }, index) => {
          const isLink = url !== '#'

          return (
            // eslint-disable-next-line react/no-array-index-key
            <Item key={index} className="position-relative text-center">
              {isLink && <Link to={url}>{title}</Link>}
              {!isLink && <BlancoLink>{title}</BlancoLink>}

              <div className="sub-menu mt-lg-5 mt-4 mb-4 mb-4">
                {submenuRight.map((b, key) => {
                  if (b.link.title === '#')
                    return <Fragment key={key}></Fragment>

                  return (
                    <Item className="mb-2 py-1" key={key}>
                      <CustomLink to={b.link.url}>{b.link.title}</CustomLink>
                    </Item>
                  )
                })}
              </div>
            </Item>
          )
        }
      )}
    </>
  )
}

export default Header
