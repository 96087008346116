import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'

import Content from 'components/shared/Content'
import ButtonDefault from 'components/elements/ButtonDefault'
import Image from 'components/shared/Image'

const ImageCol = styled.div`
  right: 0;
`

const Container = styled.div`
  /* @media (min-width: 992px) {
    min-height: 608px;
  } */
`
const Title = styled(Content)`
  & h2 {
    text-transform: uppercase;
    margin-bottom: 0;
    @media (max-width: 575px) {
      font-size: ${(props) => props.theme.font.size[40]};
    }
  }

  & p {
    font-style: italic;
    font-family: ${({ theme }) => theme.font.family.tertiary};
  }
`

const ChurchBlock = ({ data }) => {
  return (
    <section className="mt-5 pt-lg-5">
      <Container className="container">
        <div className="row align-items-center py-5 h-100">
          <div className="col-lg-6 pr-4">
            <Title content={data.title} className="mb-5" />
            <div className="">
              <Content content={data.description} className="mb-5" />
              <div className="">
                <p>
                  <ButtonDefault to={data.button_one.url}>
                    {data.button_one.title}
                  </ButtonDefault>
                </p>
              </div>
            </div>
          </div>
          <ImageCol className="col-lg-6 pl-4 mt-5 mt-lg-0">
            <Image src={data.image} />
          </ImageCol>
        </div>
      </Container>

      <Helmet>
        <script src="https://apps.elfsight.com/p/platform.js" defer></script>
      </Helmet>
      <section id="instagramFeed" className="container mt-3 mb-5 pb-3">
        <div class="elfsight-app-12cb2549-9936-47ef-b851-e5e219d88532"></div>
      </section>
    </section>
  )
}

export default ChurchBlock
