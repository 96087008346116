import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'

// Elements
import ChurchBlock from 'components/elements/ChurchBlock'

// Shared
import Image from 'components/shared/Image'

// Images
import footerLogo from 'img/footerlogo.png'
import ParseContent from 'components/shared/ParseContent'

const StyledFooter = styled.section`
  background-color: ${(props) => props.theme.color.face.black};
  color: ${(props) => props.theme.color.text.light};
  overflow: hidden;
`

const List = styled.ul`
  list-style: none;
  margin-left: -2.3rem;
`

const ListItem = styled.li`
  font-size: ${(props) => props.theme.font.size.sm};
  text-transform: uppercase;
  margin-bottom: 0.75rem;
`

const Line = styled.div`
  background-color: ${(props) => props.theme.color.face.light};
  width: 340px;
  height: 10px;
`

const Content = styled(ParseContent)`
  @media (min-width: 992px) {
    & p {
      text-align: center;
    }
  }
`

const Footer = ({ noChurch }) => {
  const { fields } = useStaticQuery(graphql`
    {
      fields: wordpressWpComponenten(wordpress_id: { eq: 98 }) {
        acf {
          church {
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 600, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            title
            description
            button_one {
              title
              url
            }
            button_two {
              title
              url
            }
          }
          menuLeft: menu_left {
            link {
              title
              url
            }
          }
          address
          menuRight: menu_right {
            link {
              title
              url
            }
          }
          social {
            channel {
              icon {
                localFile {
                  publicURL
                }
              }
              link
            }
          }
        }
      }
    }
  `)

  return (
    <>
      {!noChurch && <ChurchBlock data={fields.acf.church} />}
      <StyledFooter>
        <div className="container">
          <div className="row pt-4">
            <div className="col-lg-10 mx-auto text-center d-flex justify-content-center">
              <img src={footerLogo} alt="Logo" />
            </div>
          </div>
          <div className="row pt-5 pt-lg-3 pb-lg-5">
            <div className="col-lg-4 mb-4 mb-lg-0">
              <List className="text-lg-center">
                {fields.acf.menuLeft.map(({ link: { url, title } }, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <ListItem key={index}>
                    <Link to={url}>{title}</Link>
                  </ListItem>
                ))}
              </List>
            </div>
            <div className="col-lg-4 mb-4 mb-lg-0">
              <Content content={fields.acf.address} />
            </div>
            <div className="col-lg-4">
              <List className="text-lg-center">
                {fields.acf.menuRight.map(({ link: { url, title } }, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <ListItem key={index}>
                    <Link to={url}>{title}</Link>
                  </ListItem>
                ))}
              </List>
            </div>
          </div>
        </div>
        <div className="row py-4">
          <div className="col-12 d-flex align-items-center justify-content-end">
            {fields.acf.social.map(({ channel }, index) => (
              <div key={index} className="ml-3">
                <a
                  href={channel.link}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <Image src={channel.icon} />
                </a>
              </div>
            ))}
            <Line className="ml-3" />
          </div>
        </div>
      </StyledFooter>
    </>
  )
}

export default Footer
