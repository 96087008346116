import React from 'react'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import './typography.scss'

const mainTheme = {
  color: {
    face: {
      main: '#B09C79',
      contrast: '#FD4729',
      contrastPink: '#A3006D',
      contrastRed: '#EE3042',
      secondary: '#0350FF',
      black: '#030303',
      light: '#FFFFFF',
    },

    text: {
      main: '#000000',
      contrast: '#B09C79',
      secondary: '#FFFFFF',
      light: '#FFFFFF',
    },
  },

  font: {
    family: {
      main: 'FTKlon, Verdana',
      secondary: 'Brown Light',
      secondaryThin: 'Brown Thin',
      secondaryLight: 'Brown Light',
      secondaryBold: 'Brown Bold',
      tertiary: 'FTKlon',
    },

    size: {
      xxs: '8px',
      xs: '10px',
      s: '12px',
      sm: '14px',
      m: '16px',
      xm: '18px',
      20: '20px',
      l: '26px',
      30: '30px',
      35: '35px',
      40: '40px',
      xl: '36px',
      xxl: '45px',
      48: '48px',
      50: '50px',
      55: '55px',
      60: '60px',
      65: '65px',
      xxxl: '66px',
      70: '70px',
      80: '80px',
      89: '89px',
    },

    weight: {
      xs: 200,
      s: 300,
      m: 400,
      l: 600,
      xl: 800,
    },
  },
}

const GlobalStyle = createGlobalStyle`
  html, body {
    height: 100%;
    overflow: ${(props) => (props.isScrollable ? 'initial' : 'hidden')};
  }

  body {
    font-family: ${(props) => props.theme.font.family.secondaryLight};
    font-size: ${(props) => props.theme.font.size.m};
    color: ${(props) => props.theme.color.text.main};
  }

  ${(props) =>
    Object.keys(props.theme.color.face).map((key) => {
      return `.color-background-${key} { background-color: ${props.theme.color.face[key]}; }`
    })}

  ${(props) =>
    Object.keys(props.theme.color.text).map((key) => {
      return `.color-${key} { color: ${props.theme.color.text[key]}; }`
    })}

  ${(props) =>
    Object.keys(props.theme.font.family).map((key) => {
      return `.font-family-${key} { font-family: ${props.theme.font.family[key]}; }`
    })}

  ${(props) =>
    Object.keys(props.theme.font.size).map((key) => {
      return `.font-size-${key} { font-size: ${props.theme.font.size[key]}; }`
    })}

  ${(props) =>
    Object.keys(props.theme.font.weight).map((key) => {
      return `.font-weight-${key} { font-weight: ${props.theme.font.weight[key]}; }`
    })}

  h1,h2,h3,h4 {
    font-family: ${(props) => props.theme.font.family.tertiary};
    font-weight: 100;
    /* text-transform: uppercase; */
  }

  p {
    strong {
      font-family: ${(props) => props.theme.font.family.secondaryBold}
    }
  }

  a {
    color: inherit;

    &:hover {
      color: inherit;
      text-decoration: underline;
    }
  }

  .line-height-1 {
    line-height: 1;
  }

  .children-mb-0 {
    * {
      margin-bottom: 0;
    }
  }

  .object-contain {
    object-fit: contain;

    img {
      object-fit: contain !important;
    }
  }

  .z-index-2 {
    z-index: 2;
  }

  .position-unset {
    position: unset;
  }

  .letter-spacing-5 {
    letter-spacing: 0.5px;
  }

  .list-style-none {
    list-style: none;
  }

  h1 {
    font-size: ${({ theme }) => theme.font.size[80]};
    text-transform: uppercase;
  }

  h2 {
    font-size: ${({ theme }) => theme.font.size[60]};
    text-transform: uppercase;
    @media(max-width: 767px) {
      font-size: ${({ theme }) => theme.font.size[40]};
    }
  }

  @media (min-width: 1240px) {
    .container-extended {
      max-width: 1240px;
    }
  }
`

const Theme = ({ children, isScrollable }) => (
  <ThemeProvider theme={mainTheme}>
    <GlobalStyle isScrollable={isScrollable} />
    {children}
  </ThemeProvider>
)

export default Theme
