import React from 'react'
import CustomLink from 'components/shared/CustomLink'

const ButtonShell = ({ isAnchor, to, children, className }) =>
  !isAnchor ? (
    <CustomLink to={to} className={className}>
      {children}
    </CustomLink>
  ) : (
    <a href={to} className={className}>
      {children}
    </a>
  )

export default ButtonShell
